import { type Meta } from "~/src/api";

export async function getAll<T>(
  resourceGetter: (
    limit: number,
    offset: number,
  ) => Promise<{ meta: Meta; data: T[] }>,
) {
  const all: T[] = [];
  let total = 1;
  let current = 0;
  const limit = 100;

  while (current < total) {
    const { data, meta } = await resourceGetter(limit, current);
    all.push(...data);
    total = meta.total;
    current += limit;
  }

  return all;
}
